import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';

// Components
import { Book } from '@Components/book';
import { BookSkeleton } from '@Components/skeleton';

// Hooks
import { getBooks } from '@Hooks/useBook';

// Types
import { IBook } from '@Types/book';

export interface IBookTopic {
    title: string;
    description?: string;
    path: string;
    filters: Record<string, any>;
}

interface IBooksOfTopicProps extends IBookTopic {
    title: string;
    description?: string;
    path: string;
}

/**
 * List books of topic: Sách nói mới nhất, sách nói nổi bật,...
 * @constructor
 */
export default function BooksOfTopic(props: IBooksOfTopicProps) {
    const { title = '', description, path, filters } = props;

    const bookOfTopicRef = useRef<HTMLDivElement>(null);
    const isFetchDataRef = useRef<boolean>(false);

    const [isShowLoading, setShowLoading] = useState(false);
    const [books, setBooks] = useState<IBook[]>([]);

    useEffect(() => {
        const handleScroll = (e) => {
            if (bookOfTopicRef && bookOfTopicRef.current && !isFetchDataRef.current) {
                const rect = bookOfTopicRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                if (rect.top >= 0 && rect.top - 300 <= windowHeight) {
                    isFetchDataRef.current = true;

                    setShowLoading(true);

                    getBooksByCategory(filters).then((resultBooks) => {
                        setShowLoading(false);

                        if (resultBooks && resultBooks.length) {
                            setBooks(resultBooks);
                        }
                    });
                }
            }
        };

        // Detect element in viewport
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [bookOfTopicRef, isFetchDataRef, filters]);

    const getBooksByCategory = async (filters: Record<string, any>) => {
        const sort = ['updatedAt:desc'];

        const { data = [] } = await getBooks(filters, sort, {
            page: 1,
            pageSize: 10,
        });

        return data;
    };

    return (
        <div ref={bookOfTopicRef} className="flex flex-col">
            <div className="flex flex-col leading-[1.5rem]">
                <h2 className="m-0 hover:text-red-700">
                    <Link title={title} href={path}>
                        {title}
                    </Link>
                </h2>
                <p className="m-0">
                    {`${description}, xem tất cả `}
                    <Link title={title} className="text-blue-700" href={path}>
                        tại đây
                    </Link>
                </p>
            </div>
            <div className="mt-5 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-x-6">
                {isShowLoading ? (
                    <>
                        <BookSkeleton />
                        <BookSkeleton />
                        <BookSkeleton />
                        <BookSkeleton />
                        <BookSkeleton />
                    </>
                ) : books.length ? (
                    books.map((book, key) => {
                        return <Book key={`${book.id}-${key}`} {...book} />;
                    })
                ) : null}
            </div>
        </div>
    );
}
