import { get } from 'lodash';

// Utils & constant
import { checkJSON } from '@Src/utils';
import { LOCALSTORAGE_KEY } from '@Src/constant';

const getAccountBookPlayed = (bookId?: string) => {
    if (typeof Window !== 'undefined' && typeof localStorage !== 'undefined') {
        const storage = localStorage.getItem(LOCALSTORAGE_KEY);

        if (storage && checkJSON(storage)) {
            const results = JSON.parse(storage);

            if (get(results, 'playedBooks.books', false)) {
                let books = get(results, 'playedBooks.books', []);

                if (bookId) {
                    books = books.filter((book) => book.bookId === bookId);

                    if (books.length) {
                        return {
                            ...books[0],
                        };
                    }
                } else {
                    // Sorted bookId by updatedAt
                    books.sort((a, b) => {
                        if (a.updatedAt > b.updatedAt) {
                            return -1;
                        } else if (a.updatedAt < b.updatedAt) {
                            return 1;
                        }

                        return 0;
                    });

                    return books;
                }
            }
        }
    }

    return null;
};

export { getAccountBookPlayed };
