export default function BookSkeleton() {
    return (
        <div className="flex flex-col pb-6 max-w-[203px] animate-pulse">
            <div className="lg:min-w-[202px] lg:h-[285px] h-[240px] rounded bg-gray-200"></div>
            <div className="flex flex-col space-y-2 pt-2">
                <div className="w-full h-[25px] bg-gray-200 rounded"></div>
                <div className="w-2/3 h-[20px] bg-gray-200 rounded"></div>
            </div>
        </div>
    );
}
