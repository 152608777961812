import { plainToInstance } from 'class-transformer';
import { get } from 'lodash';

// Service
import { BOOK_SERVICE_DEFINED, BookModel } from '@Src/services/book';
import { ResponseMetaModel } from '@Services/general';
import Service from '@Src/services/api';

// Utils
import { parseError, parseResponse } from '@Src/utils';

// Types
import { IPagination } from '@Types/general';
import { IBook } from '@Types/book';

interface IBooksParameters {
    filters?: string;
    sort?: string;
    pagination?: string;
}

interface IBooksResult {
    isError: boolean;
    data: IBook[];
    meta: Record<string, any>;
}

interface ISearchBooksParameters {
    q: string;
    pagination?: string;
}

const getBooks = async (filters?: Record<string, any>, sort?: string[], pagination?: IPagination) => {
    const service = new Service({
        baseUrl: BOOK_SERVICE_DEFINED.baseUrl,
        url: BOOK_SERVICE_DEFINED.books(),
    });

    let parameters: IBooksParameters = {};

    // Filters reference => https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering
    if (typeof filters !== 'undefined' && Object.keys(filters).length) {
        parameters = {
            ...parameters,
            filters: JSON.stringify(filters),
        };
    }

    // Sort reference => https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/sort-pagination.html#sorting
    if (typeof sort !== 'undefined' && Array.isArray(sort) && sort.length) {
        parameters = {
            ...parameters,
            sort: JSON.stringify(sort),
        };
    }

    // Pagination reference => https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/sort-pagination.html#pagination
    if (typeof pagination !== 'undefined' && Object.keys(pagination).length) {
        parameters = {
            ...parameters,
            pagination: JSON.stringify(pagination),
        };
    }

    let result: IBooksResult = {
        isError: true,
        data: [],
        meta: {},
    };

    const { isError = false, data } = await service
        .get({
            data: parameters,
        })
        .then((response) => parseResponse(response))
        .catch((error) => parseError(error));

    if (!isError) {
        const books = plainToInstance(BookModel, get(data, 'data.data', {}), {});
        const meta = plainToInstance(ResponseMetaModel, get(data, 'data.meta', {}), {});

        result = {
            ...result,
            isError: false,
            data: JSON.parse(JSON.stringify(books)),
            meta: JSON.parse(JSON.stringify(meta)),
        };
    }

    return result;
};

const getBookById = async (bookId: string, args?: Record<string, any>) => {
    const service = new Service({
        baseUrl: BOOK_SERVICE_DEFINED.baseUrl,
        url: BOOK_SERVICE_DEFINED.bookById(bookId),
    });

    let parameters: Record<string, any> = {};

    // Draft view
    if (args && args.draft) {
        parameters.draft = args.draft;
    }

    let result = {
        isError: true,
        data: {},
    };

    const { isError = false, data } = await service
        .get({
            data: parameters,
        })
        .then((response) => parseResponse(response))
        .catch((error) => parseError(error));

    if (!isError) {
        const book = plainToInstance(BookModel, get(data, 'data.data', {}), {});

        result = {
            ...result,
            isError: false,
            data: JSON.parse(JSON.stringify(book)),
        };
    }

    return result;
};

const search = async (q: string, pagination?: IPagination) => {
    const service = new Service({
        baseUrl: BOOK_SERVICE_DEFINED.baseUrl,
        url: BOOK_SERVICE_DEFINED.search(),
    });

    let result = {
        isError: true,
        data: [],
    };

    let parameters: ISearchBooksParameters = {
        q,
    };

    if (typeof pagination !== 'undefined' && Object.keys(pagination).length) {
        parameters = {
            ...parameters,
            pagination: JSON.stringify(pagination),
        };
    }

    const { isError = false, data } = await service
        .get({
            data: parameters,
        })
        .then((response) => parseResponse(response))
        .catch((error) => parseError(error));

    if (!isError) {
        const books = plainToInstance(BookModel, get(data, 'data.data', []), {});

        result = {
            ...result,
            isError: false,
            data: JSON.parse(JSON.stringify(books)),
        };
    }

    return result;
};

export { getBooks, getBookById, search };
