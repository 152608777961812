import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Select } from 'antd';
import Link from 'next/link';
import Redis from 'ioredis';

// Components
import { DefaultHead, DefaultHeader, DefaultFooter } from '@Components/layouts';
import { Book } from '@Components/book';
import { BooksOfTopic, IBookTopic } from '@Components/books';
import { BookSkeleton } from '@Components/skeleton';

// Hooks
import { getAppInfo } from '@Hooks/useApp';
import { getBooks } from '@Hooks/useBook';
import { getCategories } from '@Hooks/useCategory';
import { getAccountBookPlayed } from '@Hooks/useAccount';

// Constant
import { MAX_PAGE_NUMBER, REDIS_HOST, REDIS_PORT } from '@Src/constant';

// Types
import { IAppInfo } from '@Types/app';
import { IBook } from '@Types/book';
import { IResponseMeta } from '@Types/general';
import { ICategory } from '@Types/category';

// Utils
import { removeAccents, checkJSON } from '@Src/utils';

interface IHomeProps {
    appInfo: IAppInfo;
    books: IBook[];
    categories: ICategory[];
    meta: IResponseMeta;
}

export default function Home(props: IHomeProps) {
    const router = useRouter();
    const { appInfo, books = [], categories = [], meta } = props;

    const playedBooksOfUser = getAccountBookPlayed();

    const [isComponentDidMount, setComponentDidMount] = useState(false);
    const [searchCategoryValue, setSearchCategoryValue] = useState('');
    const [isFetchPlayedBooks, setFetchPlayedBooks] = useState(false);
    const [playedBooks, setPlayedBooks] = useState<IBook[]>([]);

    useEffect(() => {
        // Update component did mount
        setComponentDidMount(true);

        if (playedBooksOfUser && playedBooksOfUser.length) {
            const bookIds = playedBooksOfUser.map((book) => book.bookId);

            if (bookIds.length) {
                // Fetch books by bookId
                getBooksByIds(bookIds).then((books) => {
                    setFetchPlayedBooks(true);

                    // @todo calculator percentage listen of user to success
                    if (books.length) {
                        let playedBookSorted: IBook[] = [];
                        for (const bookId of bookIds) {
                            const book = books.find((book) => book.id === bookId);

                            if (book) {
                                playedBookSorted = playedBookSorted.concat([book]);
                            }
                        }

                        if (playedBookSorted.length) {
                            setPlayedBooks(playedBookSorted);
                        }
                    } else {
                        setPlayedBooks([]);
                    }
                });
            }
        }
    }, []);

    const getBooksByIds = async (bookIds: string[]) => {
        const filters = {
            id: {
                $eq: bookIds, // in array of book id
            },
        };

        if (bookIds.length) {
            const { data = [] } = await getBooks(filters);

            return data;
        }

        return [];
    };

    const getCategoriesOrdered = () => {
        let categoriesOrdered = [];
        // Categories group by first letter of category name
        let groupByFirstLetter = {};
        let ordered = {};
        if (categories.length) {
            categories.forEach((category) => {
                if (category.name) {
                    const firstLetter = removeAccents(category.name[0]);

                    if (groupByFirstLetter[firstLetter]) {
                        groupByFirstLetter[firstLetter].push(category);
                    } else {
                        groupByFirstLetter[firstLetter] = [category];
                    }
                }
            });
        }

        if (Object.keys(groupByFirstLetter).length) {
            ordered = Object.keys(groupByFirstLetter)
                .sort()
                .reduce((obj, key) => {
                    obj[key] = groupByFirstLetter[key];
                    return obj;
                }, {});
        }

        Object.keys(ordered).forEach((key) => {
            categoriesOrdered = categoriesOrdered.concat(ordered[key]);
        });

        return categoriesOrdered;
    };

    const categoriesOrdered = getCategoriesOrdered();

    const onChange = (value: string) => {
        if (value) {
            router.push(`/${value}.html`);
        }
    };

    const onSearchCategory = (value: string) => {
        setSearchCategoryValue(value);
    };

    const topicsAvailable: IBookTopic[] = [
        {
            title: 'Sách nói nổi bật',
            description: 'Sách nói nghe nhiều tuần qua',
            path: '/sach-noi-noi-bat.html',
            filters: {
                'properties.star': {
                    $eq: true,
                },
            },
        },
        {
            title: 'Sách nói Kỹ Năng Sống',
            description: 'Sách nói về kỹ năng sống',
            path: '/ky-nang-song.html',
            filters: {
                'categories.slug': {
                    $eq: 'ky-nang-song',
                },
            },
        },
        {
            title: 'Sách nói Tâm Linh & Tôn Giáo',
            description: 'Sách nói về tâm linh và tôn giáo',
            path: '/tam-linh-ton-giao.html',
            filters: {
                'categories.slug': {
                    $eq: 'tam-linh-ton-giao',
                },
            },
        },
        {
            title: 'Sách nói Mẹ & Bé',
            description: 'Sách nói về mẹ và bé',
            path: '/nuoi-day-con.html',
            filters: {
                'categories.slug': {
                    $eq: 'nuoi-day-con',
                },
            },
        },
    ];

    return (
        <>
            <DefaultHead {...appInfo} />
            <main>
                <DefaultHeader {...appInfo} />
                <div className="wrapper-container container px-4 xl:px-0 mx-auto pt-8">
                    <h2>Sách nói</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-3 leading-[1.5rem]">
                        <div className="col-span-2">
                            <p>
                                Nghe sách nói từ những cuốn sách bán chạy nhất của các tác giả Việt Nam và nước ngoài,
                                miễn phí, cập nhật nhanh chóng.
                            </p>
                        </div>
                        <div className="flex justify-end">
                            <div className="h-[42px]">
                                <Select
                                    id="filter-books-by-category"
                                    className="rounded w-[200px]"
                                    showSearch
                                    placeholder="Thể loại sách nói"
                                    optionFilterProp="children"
                                    aria-label="Thể loại sách nói"
                                    onChange={onChange}
                                    onSearch={onSearchCategory}
                                    filterOption={(input, option) =>
                                        (removeAccents(option?.label) ?? '')
                                            .toLowerCase()
                                            .includes(removeAccents(input).toLowerCase())
                                    }
                                    options={categoriesOrdered
                                        .filter((category: ICategory) => (searchCategoryValue ? true : category.star))
                                        .map((category: ICategory) => {
                                            return {
                                                label: category.name,
                                                value: category.slug,
                                            };
                                        })}
                                />
                            </div>
                        </div>
                    </div>

                    {isComponentDidMount && playedBooksOfUser?.length ? (
                        <div className="mt-4">
                            <div className="flex flex-col">
                                <div className="flex flex-col leading-[1.5rem]">
                                    <h2 className="m-0 hover:text-red-700">Sách đang nghe của bạn</h2>
                                    <p className="m-0">Danh sách dựa trên những lần nghe trước đó của bạn</p>
                                </div>
                            </div>
                            <div className="flex w-full overflow-hidden mt-5">
                                <div className="flex flex-row overflow-scroll no-scrollbar gap-x-3">
                                    {!isFetchPlayedBooks ? (
                                        <>
                                            <BookSkeleton />
                                            <BookSkeleton />
                                            <BookSkeleton />
                                            <BookSkeleton />
                                            <BookSkeleton />
                                            <BookSkeleton />
                                        </>
                                    ) : playedBooks.length ? (
                                        playedBooks.map((book, key) => {
                                            return (
                                                <Book
                                                    key={book.id || key}
                                                    {...book}
                                                    isShowBackdrop={true}
                                                    percentSuccess={0}
                                                />
                                            );
                                        })
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className="space-y-6 mt-4">
                        <div className="flex flex-col">
                            <div className="flex flex-col leading-[1.5rem]">
                                <h2 className="m-0 hover:text-red-700">
                                    <Link title="Sách nói mới nhất" href={`/sach-noi-moi-nhat.html`}>
                                        Sách nói mới nhất
                                    </Link>
                                </h2>
                                <p className="m-0">
                                    Sách nói mới cập nhật, xem tất cả{' '}
                                    <Link
                                        className="text-blue-700"
                                        title="Xem tất cả sách nói mới cập nhật"
                                        href={'/sach-noi-moi-nhat.html'}
                                    >
                                        tại đây
                                    </Link>
                                </p>
                            </div>
                            <div className="mt-5 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-x-6">
                                {books.length
                                    ? books.map((book, key) => {
                                          return <Book key={book.id || key} {...book} />;
                                      })
                                    : null}
                            </div>
                        </div>

                        {topicsAvailable.map((bookTopic) => {
                            return (
                                <BooksOfTopic
                                    key={bookTopic.path}
                                    title={bookTopic.title}
                                    description={bookTopic.description}
                                    path={bookTopic.path}
                                    filters={bookTopic.filters}
                                />
                            );
                        })}
                    </div>
                </div>
                <DefaultFooter {...appInfo} />
            </main>
        </>
    );
}

export async function getServerSideProps({ query, res }) {
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    // Create redis instant
    let redisConnection: any = null;
    try {
        redisConnection = new Redis({
            host: REDIS_HOST,
            port: REDIS_PORT,
            maxRetriesPerRequest: 3,
        });

        redisConnection.on('error', async (e) => {
            await redisConnection.disconnect();

            console.log(`Connect redis ${REDIS_HOST}:${REDIS_PORT} error: ${e}`);
        });
    } catch (error) {
        console.log(`Connect redis ${REDIS_HOST}:${REDIS_PORT} error`);
    }

    const { trang = 1, draft = false } = query;
    let appInfo: IAppInfo | null = null;
    let categories: ICategory[] | undefined;
    const REDIS_BOOK_HOME_KEY = 'nghesachnoi:home';

    // Get info from redis cached
    if (redisConnection) {
        try {
            const cached: any = await redisConnection.get(REDIS_BOOK_HOME_KEY); // nghesachnoi:home

            if (cached && checkJSON(cached) && !draft) {
                redisConnection.disconnect();

                return {
                    props: {
                        ...JSON.parse(cached),
                    },
                };
            }
        } catch (error) {
            redisConnection = null;
        }
    }

    // Get app information
    const { isError = false, data } = await getAppInfo();

    if (!isError && Object.keys(data).length) {
        appInfo = { ...data } as IAppInfo;

        if (+trang > 1 && appInfo && appInfo?.title) {
            appInfo = {
                ...appInfo,
                title: `${appInfo.title} - Trang ${trang}`,
            };
        }
    }

    // Get all categories
    const allCategories = await getCategories();

    if (allCategories?.data && allCategories.data.length) {
        categories = allCategories.data;
    }

    // Get list books
    let sort: string[] = ['updatedAt:desc'];
    let page = 1;

    // Validate page number
    if (trang && !isNaN(trang) && +trang <= MAX_PAGE_NUMBER) {
        page = +trang;
    }

    const { data: books = [], meta = {} } = await getBooks(undefined, sort, {
        page: page,
        pageSize: 10,
    });

    // Cache all info of homepage
    if (redisConnection) {
        await redisConnection.set(
            REDIS_BOOK_HOME_KEY,
            JSON.stringify({
                appInfo,
                books,
                categories,
                meta,
            })
        );
        await redisConnection.expire(REDIS_BOOK_HOME_KEY, 600); // 10 minutes

        // Disconnect redis
        redisConnection.disconnect();
    }

    return {
        props: {
            appInfo,
            books,
            categories,
            meta,
        },
    };
}
